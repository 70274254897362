'use client';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import type { PropsWithChildren } from 'react';

import { useUser } from '@/contexts/useUser';

interface EONPostHogProviderProps extends PropsWithChildren {
  apiKey: string | undefined;
}

export const EONPostHogProvider = (props: EONPostHogProviderProps) => {
  const { user, currentProjectId } = useUser();

  if (!user?.id || !props.apiKey) {
    return props.children;
  }

  if (user.email?.endsWith('support@eon.io')) {
    return props.children;
  }

  if (typeof window !== 'undefined') {
    posthog.init(props.apiKey, {
      api_host: '/ingest',
      ui_host: 'https://us.posthog.com',
      person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
      mask_all_text: true,
      session_recording: {
        maskAllInputs: true,
        maskTextSelector: '*',
      },
    });
  }

  posthog.identify(user.id, {
    email: user.email,
    name: user.givenName,
    project: currentProjectId,
    eonAccountId: user.eonAccount.id,
  });

  return <PostHogProvider client={posthog}>{props.children}</PostHogProvider>;
};
